.floating-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  
  .main-button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #14569d;
    color: white;
    font-size: 24px;
    cursor: pointer;
    border: none;
    outline: none;
    transition: all 0.3s ease; /* Added transition property */
  }

  .main-button i{
    font-size: 2rem;
    color: white;
  }
  
  .menu-buttons {
    position: absolute;
    bottom: 70px;
    right: 5px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: none; 
    animation: slideUp 0.3s ease forwards;
  }

  .menu-buttons.show {
    opacity: 1; /* Show the menu buttons */
    pointer-events: auto; /* Enable pointer events when visible */
    animation: slideDown 0.3s ease backwards;
  }
  
  .sub-button {
    width: 50px;
    height: 50px;
    background-color: #232425;
    color: white;
    border: none;
    outline: none;
    margin-bottom: 10px;
    border-radius: 50%;
    cursor: pointer;
  }

  .sub-button svg{
    font-size: 20px;
  }
  
  .floating-button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border: none;
    outline: none;
  }

  @keyframes slideUp {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .calendar-filter-wrap .form-control{
    /* height: 40px;
    width: 250px; */
    border-radius: 10px;
    padding: 12px 40px;
  }

  .search-filter-wrap .form-control{
    width: 500px;
    border-radius: 10px;
    padding: 12px 12px;
  }