.dashboard-stats-card{
    background-color: #d6e0ff;
    border-radius: 20px;
    box-shadow: 2px 20px 10px 0px rgba(124,124,124,0.43);
    overflow: hidden;
    padding-bottom: 50px;
    border: none;
  }
  /* .dashboard-stats-card #heading {
    font-size: 18px;
  } */
  .darkWhite {
    color: #10a1b8;
  } 
  .dashboard-stats-card .top-area {
    border-radius: 10px 10px 25px 25px;
    padding: 20px 40px;
    
  }
  .dashboard-stats-card .top-area i{
    color: black;
    font-size: 20px;
  }
  
  .dashboard-stats-card .area-bottom{ 
      position: relative;
      display: flex;
  }
  
  .dashboard-stats-card .area-bottom .img-wrap{
      transform:  rotate(-30deg);
      position: absolute;
      bottom: -60px;
      right: 10px;
  }
  .dashboard-stats-card .area-bottom .img-wrap img{
      width: 100px;
  }

  .dashboard-stats-card .area-bottom .content h4{
      color: black;
  }
  .dashboard-stats-card .area-bottom .content h6{
      color: rgb(64, 60, 60);
      padding-bottom: 10px;
  }
  
  /* 
  .dashboard-stats-card #desc {
    color: #b8babb;
    font-weight: 500;
  } */


  .prime-table th,
.prime-table th > * {
    background-color: #d2e0ed; /* Change the background color as needed */
    color: #495057;
    /* padding: 10px 0px;  */
    font-size: 15px;
}

.prime-table .p-datatable .p-datatable-thead th{
    border-left: 2px solid white;
}

.prime-table .p-datatable .p-datatable-thead th:first-child,
.prime-table .p-datatable .p-datatable-thead td:first-child {
    /* border-radius: 10px 0px 0px 10px; */
    padding-left: 15px;
    border-left: none;
}

.prime-table .p-datatable .p-datatable-thead th:last-child,
.prime-table .p-datatable .p-datatable-thead td:last-child {
    /* border-radius: 0px 10px 10px 0px; */
    padding-right:15px;
}

.prime-table .top-rounded{
    border-radius: 40px 40px 40px 40px;
}

.prime-table .p-datatable .p-datatable-tbody > tr {
    border-bottom: 8px solid #f2f5f7; /* Add border at the bottom of each row */
    border-top: 8px solid #f2f5f7; /* Add border at the bottom of each row */
}

.prime-table .p-datatable .p-datatable-tbody > tr td:first-child {
    border-radius: 10px 0px 0px 10px;
}
.prime-table .p-datatable .p-datatable-tbody > tr td:last-child {
    border-radius: 0px 10px 10px 0px;
}

.prime-table .p-dropdown-items{
    width: 75px;
}

.prime-table .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus{
    background-color: white;
}


.prime-table .p-dropdown-panel .p-dropdown-items{
    padding: 0;
} 


.mz-stats-card{
    border-radius: 15px;
}

.mz-chart-card .chart-label{
    width: 25px;
    height: 8px;
    border-radius: 20px;
    content: none;
}