.deal_description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.deal_name_dashboard {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.img-sm {
    width: 50px;
    height: 59px;
    object-fit: contain;
}

.deal_name_commit {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.range_slider {
    margin-top: 17px;
}

.range-slider__range {
    background-color: #f55278 !important;
}

.range-slider__thumb {
    background-color: #EF305E !important;
    width: 3px !important;
    height: 20px !important;
    border-radius: 0 !important;
}

.range-slider__thumb:hover {
    background-color: #f8063e !important;
}

.thumb-values {
    position: absolute;
    top: -30px;
    font-size: 14px;
    color: #c92e52;
    /* Replace with your desired color */
}

.thumb-values2 {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    /* Replace with your desired color */
}

.payment_box {
    border: 3px solid #FF8B8C;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
}

.bank_icon {
    font-size: 22px !important;
    color: #c4c4c4 !important;
}

.ach_account p {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}

.payment_btn button {
    background-image: linear-gradient(#f1578b, #EA2467);
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    padding: 4px 10px;
    font-weight: 500;
}

.payment_btn button:hover {
    background-image: linear-gradient(#EA2467, #941c44);
}

.left_side {
    padding: 15px;
}

.right_side {
    padding: 15px;
}

.heading {
    margin-top: 30px;
}

.heading p {
    font-size: 16px;
    font-weight: 600;
}

.heading2 {
    text-align: center;
}

.heading2 .balance {
    font-size: 22px;
    font-weight: 600;
}

.heading2 .amount {
    font-size: 24px;
    font-weight: 600;
    margin-top: -10px;
    color: #E49C58;
}

.orders {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c4c4c4;
}

.orders p {
    padding-top: 15px;
}

.payment_foot {
    margin-top: 100px;
}

.payment_foot p {
    margin-top: -10px;
    font-weight: 500;
}

.pay_types {
    display: flex;
    margin-top: 30px;
}

.form-check-input[type="radio"] {
    width: 16px;
    height: 16px;
    margin-top: 0px;
}

.form-check-input[type="radio"]:checked {
    background-color: #EA2467;
    border-color: #EA2467;
}

.form-check {
    margin-right: 20px;
}

.form-check-label {
    margin-left: -10px;
}

.payment_para {
    margin-top: 40px;
}

.payment_para p {
    margin-top: -10px;
}

.amount2 {
    margin-top: 25px;
    margin-bottom: 0;
}

.footer_btn {
    text-align: right;
}

.footer_btn button {
    background-image: linear-gradient(#f1578b, #EA2467);
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    padding: 4px 10px;
    font-weight: 500;
}

.footer_btn button i {
    color: #fff;
}

.footer_btn button:hover {
    background-image: linear-gradient(#EA2467, #941c44);
}

.foot_btn {
    text-align: left;
    bottom: 0;
}

.foot_btn .open_btn {
    background-image: linear-gradient(#f1578b, #EA2467);
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    padding: 4px 10px;
    font-weight: 500;
}

.foot_btn .open_btn:hover {
    background-image: linear-gradient(#EA2467, #941c44);
}

.foot_btn .close_btn {
    background-image: linear-gradient(#f1578b, #EA2467);
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    padding: 4px 10px;
    font-weight: 500;
    margin-right: 5px;
}

.foot_btn .close_btn:hover {
    background-image: linear-gradient(#EA2467, #941c44);
}

.support_modal .modal-content {
    width: 50%;
    left: 25%;
    border-radius: 14px;
}

.support_modal .modal-content .card .form_card {
    height: 35rem;
}

.form_card {
    padding: 10px 40px;
}

.supp_type label {
    font-weight: 500;
}

.title_field {
    margin-top: 20px;
}

.title_field label {
    font-weight: 500;
}

.model-foot-btn .canc_btn {
    background-color: transparent;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    color: #757575;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
}

.model-foot-btn .supp_btn {
    background-image: linear-gradient(#f1578b, #EA2467);
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    padding: 3px 10px;
    font-weight: 500;
}

.model-foot-btn .supp_btn:hover {
    background-image: linear-gradient(#EA2467, #941c44);
}

.model-foot-btn .supp_btn i {
    color: #fff;
}

.faq_heading {
    text-align: center;
    margin-top: -40px;
}

.faq_heading p {
    color: #0273B5;
    font-size: 30px;
    font-weight: 600;
}

.faq_search_input {
    text-align: center;
}

.search_input {
    width: 35%;
    margin: auto;
}

.search_input input {
    background-color: transparent;
    border: 2px solid #f595b5;
    border-radius: 5px;
    padding: 5px 10px;
}

.search_input input::placeholder {
    color: #ABAAAB;
    font-weight: 500;
}

.faq_content {
    padding: 30px;
}

.accordion-button {
    font-weight: 600;
}

.help_container {
    text-align: center;
}

.logo p {
    font-size: 30px;
    font-weight: 600;
    color: #0072B4;
}

.contact_detail1 {
    margin-top: 30px;
}

.contact_detail1 .para {
    font-size: 20px;
    margin-bottom: 0;
}

.contact_detail1 .para1 {
    font-size: 20px;
    margin: auto;
    width: 50%;
}

.contact_detail1 .para2 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: -5px;
}

.contact_detail1 .para3 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 0;
}

.contact_detail1 .para u {
    font-weight: 500;
}

.contact_detail2 {
    margin-top: 30px;
}

.contact_detail2 .para {
    font-size: 20px;
    margin-bottom: 0;
}

.contact_detail2 .para u {
    font-weight: 500;
}

.report_btn {
    margin-top: 20px;
}

.report_btn button {
    background-image: linear-gradient(#f1578b, #EA2467);
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
}

.report_btn button:hover {
    background-image: linear-gradient(#EA2467, #941c44);
}

.report_btn button i {
    color: #fff;
    font-size: 18px;
}

.support_modal2 .modal-content {
    width: 75%;
    left: 12.5%;
    border-radius: 14px;
}

.bug_report_form {
    padding: 20px;
    margin-top: -20px;
}

.terms_heading {
    text-align: center;
    margin-top: -40px;
}

.terms_heading p {
    color: #0273B5;
    font-size: 30px;
    font-weight: 600;
}

.terms_content {
    padding: 50px;
}

.box1 {
    background-color: #f0efef;
    padding: 15px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}

.box1 a {
    font-weight: 500;
    padding: 5px 10px;
}

.box1 ul li a {
    font-weight: 500;
    padding: 5px 10px;
    color: black !important;
    padding-left: 10px !important;
    margin-left: -10px !important;
}

.box1 ul li a:hover {
    background-color: #0173B5 !important;
    border-radius: 20px !important;
    color: #fff !important;
}

.box1 .active {
    background-color: #0173B5 !important;
    border-radius: 20px !important;
    color: #fff !important;
    padding: 5px 10px;
    padding-left: 10px !important;
}

.option_head {
    font-size: 17px;
    color: #EA2467;
    font-weight: 600;
}

.profile_head {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    border-bottom: 1px solid #c4c4c4;
}

.user_id p {
    font-weight: 600;
    font-size: 14px;
}

.create_date p {
    font-size: 12px;
}

.profile_content {
    margin-top: 4px;
}

.box2 {
    border: 1px solid #c4c4c4;
    height: 180px;
    width: 220px;
}

.user_form {
    width: 100%;
}

.user_form .row {
    text-align: start;
}

.user_form .row .col-6 {
    display: flex;
    flex-direction: column;
}

.user_form .row .col-6 input {
    border-color: 1px solid #c4c4c4;
}

.email_input {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.email_input2 {
    display: flex;
    flex-direction: column;
    margin-top: -10px;
}

.change_mail {
    text-align: end;
}

.change_mail p {
    color: #EA2467;
}

.change_mail p i {
    color: #EA2467;
}

.user_left_content {
    margin-top: 20px;
}

.checkbox input:checked {
    background-color: #EA2467;
    color: #EA2467;
}

.cell_phone {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.work_phone {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.dob {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
}

.company {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
}

.section_2 {
    margin-top: 60px;
}

.sec_2_head p {
    font-size: 16px;
    font-weight: 600;
}

.horizon_row {
    margin-top: -8px;
}

.sec_2_address {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}

.sec_2_address2 {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.sec_2_city {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}

.sec_2_zip {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.sec_2_state {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}

.state_input {
    padding: 3px 0px;
}

.sec_2_country {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.country_input {
    padding: 3px 0px;
}

.foot_checkboxes {
    display: flex;
    flex-direction: column;
}

.foot_checkboxes .checkbox1 {
    margin-top: 10px;
}

.foot_checkboxes .checkbox2 {
    margin-top: 20px;
}

.left_heading {
    margin-top: 20px;
}

.left_heading p {
    font-size: 16px;
    font-weight: 600;
}

.right_heading {
    margin-top: 20px;
}

.right_heading p {
    font-size: 16px;
    font-weight: 600;
}

.sec_3_foot_btn {
    text-align: center;
}

.sec_3_foot_btn button {
    background-color: transparent;
    border: 1px solid #EA2467;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
    padding: 3px 15px;
}

.sec_3_foot_btn button i {
    color: black;
}

.sec_3_right_para .para_2 {
    margin-top: -5px;
}

.link_copy {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.copy_link {
    display: flex;
}

.copy_link .p_1 {
    border: 1px solid #757575;
    width: 90%;
    padding: 5px;
    font-weight: 500;
    background-color: #CCCBCD;
}

.copy_link .p_2 {
    border: 1px solid #757575;
    width: 5%;
    text-align: center;
    padding: 5px;
}

.copy_link .p_2 i {
    color: black;
}

.link_id {
    margin-top: 7px;
}

.link_id .link_id2 .p_1 {
    border: 1px solid #757575;
    padding: 5px;
    font-weight: 500;
}

.horizon_row2 {
    margin-top: 30px;
}

.footer_btn2 {
    text-align: right;
    margin-left: 20px;
    margin-top: 15px;
}

.footer_btn2 button {
    background-image: linear-gradient(#f1578b, #EA2467);
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    padding: 4px 20px;
    font-weight: 500;
    height: 43px;
}

.footer_btn2 button:hover {
    background-image: linear-gradient(#EA2467, #941c44);
}

.footer_btn2 button i {
    color: #fff;
}

.footer_btn3 {
    text-align: left;
}

.footer_btn3 button {
    background-image: linear-gradient(#f1578b, #EA2467);
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    padding: 4px 10px;
    font-weight: 500;
}

.footer_btn3 button:hover {
    background-image: linear-gradient(#EA2467, #941c44);
}

.footer_btn3 button i {
    color: #fff;
}

.footer_btn4 button {
    border: 1px solid #c4c4c4;
    background-image: linear-gradient(#faf9f9, #dddddd);
    border-radius: 3px;
    color: black;
    font-size: 12px;
    padding: 4px 10px;
    font-weight: 500;
}

.footer_btn4 button:hover {
    background-image: linear-gradient(#f5f5f5, #cccccc);
}

.error_msg {
    color: red;
    font-weight: 500;
    font-size: 12px;
    position: absolute;
}

input[type="checkbox"]:checked {
    background-color: #6634ed;
    border-color: #6634ed !important;
}

input[type="text"]:focus {
    border-color: #6634ed;
}

input[type="number"]:focus {
    border-color: #6634ed;
}

input[type="tel"]:focus {
    border-color: #6634ed;
}

input[type="file"]:focus {
    border-color: #6634ed;
}

.tracking_import {
    width: 530px;
    border-color: #EA2467;
}

.tracking_import:focus {
    outline: none;
    border-color: #EA2467;
    border-radius: 3px;
    box-shadow: 0 0 4px #EA2467;
}

.support_import:focus {
    outline: none;
    border-color: #EA2467;
}

.form-select:focus {
    outline: none;
    border-color: #EA2467;
}

.success_active {
    background-color: #c5f5dc;
}

.danger_active {
    background-color: #f9d4df;
}

.warning_active {
    background-color: #F9F1BB
}

.accordion-button:not(.collapsed) {
    color: #0273B5;
    background-color: #fff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    border-left: 5px solid #EA2467;
}

.custom-shadow {
    box-shadow: 0 0 3px #07BC0C;
}

.table_container {
    max-height: 350px;
    overflow-y: auto;
}

/* .nav-line-tabs .nav-item .nav-link.active {
    border-bottom: 1px solid #EA2467;
} */

.custom-form-control {
    font-size: 12px;
    padding: 2px 5px;
}

.custom-update-btn {
    font-size: 12px;
    padding: 2px 5px 2px 8px !important;
    margin-top: 3px;
    margin-bottom: 4px;
}

.custom-cancle-btn {
    font-size: 12px;
    padding: 2px 5px 2px 8px !important;
    margin-top: 3px;
    margin-bottom: 4px;
}

/* Mz Styles */
.profile-main-tabs{
    border-bottom: 1px solid #5dc1fb;
}

.profile-main-tabs .nav-item .nav-link{
    color: #a1a5b7;
}
.profile-main-tabs .nav-item .nav-link.active{
    background-color: #009ef7;
    color: white;
    border-bottom: 1px solid #009ef7;
}

.profile-main-tabs .nav-item .nav-link:hover {
    background-color: transparent;
    color: #009ef7;
    border: 1px solid #009ef7;
}

.profile-secondary-tabs{
    border-bottom: 1px solid #a1a5b7;
}

.profile-secondary-tabs .nav-item .nav-link{
    color: #a1a5b7;
}

.profile-secondary-tabs .nav-item .nav-link.statement-tab.active{
    background-color: #07bc0c;
    color: white;
    border-bottom: 1px solid #07bc0c;
}

.profile-secondary-tabs .nav-item .nav-link.withdraw-tab.active{
    background-color: #f1c40f;
    color: white;
    border-bottom: 1px solid #f1c40f;
}

.profile-secondary-tabs .nav-item .withdraw-tab:hover , .profile-secondary-tabs .nav-item .statement-tab:hover{
    background-color: transparent;
    color: #a1a5b7;
    border: 1px solid #a1a5b7;
}


.announcement-modal{
    position: relative;
}

.announcement-modal .modal-content{
    border-radius: 30px;
}

.announcement-modal-close{
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background-color: rgb(85, 85, 85);
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}


.announcement-modal .announcement-dates {
    margin-top: 10px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 8px;
    font-size: 14px;
    color: #333;
    text-align: left;
}

.announcement-modal .announcement-dates strong {
    color: #007bff;
}


.deal-card{
    /* background-color: #f5f8fa; */
    background-color: white;
    border: 1px solid rgb(207, 222, 255);
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.deal-card:hover{
    background-color: white;
    border: none;
    box-shadow: 0px 0px 4px 2px rgb(201, 201, 201);
    transform:scale(1.05);
    z-index: 10;
    transition: ease-out 0.5s;
}

.deal-card .commit-btn{
    border-radius: 25px;
    background-color: white;
    border: none;
}

.deal-card .deal-btn{
    border-radius: 25px;
    background-color: white;
    border: none;
}
