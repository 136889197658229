.commitments-table th,
.commitments-table th > * {
    background-color: #d2e0ed; /* Change the background color as needed */
    color: #495057;
    padding: 10px 0px; /* Adjust padding as needed */
    font-size: 15px;
}

.commitments-table .p-datatable .p-datatable-thead th{
    border-left: 2px solid white;
}

.commitments-table .p-datatable .p-datatable-thead th:first-child,
.commitments-table .p-datatable .p-datatable-thead td:first-child {
    /* border-radius: 10px 0px 0px 10px; */
    padding-left: 15px;
    border-left: none;
}

.commitments-table .p-datatable .p-datatable-thead th:last-child,
.commitments-table .p-datatable .p-datatable-thead td:last-child {
    /* border-radius: 0px 10px 10px 0px; */
    padding-right:15px;
}

.commitments-table .top-rounded{
    border-radius: 40px 40px 40px 40px;
}

.commitments-table .p-datatable .p-datatable-tbody > tr {
    border-bottom: 8px solid #f2f5f7; /* Add border at the bottom of each row */
    border-top: 8px solid #f2f5f7; /* Add border at the bottom of each row */
}

.commitments-table .p-datatable .p-datatable-tbody > tr td:first-child {
    border-radius: 10px 0px 0px 10px;
}
.commitments-table .p-datatable .p-datatable-tbody > tr td:last-child {
    border-radius: 0px 10px 10px 0px;
}

.commitments-table .p-dropdown-items{
    width: 75px;
}

.commitments-table .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus{
    background-color: white;
}


.commitments-table .p-dropdown-panel .p-dropdown-items{
    padding: 0;
} 