.deal_colors {
    list-style: none;
    padding: 0;
    margin: 0;
}
.deal_colors li{
    display: inline-block;
}
.color-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width:40px;
    height:40px;
    margin: 5px;
    border-style: solid;
    border: 0;
   
}
.colorBox {
    width:30px;
    height:30px;
    padding: 10px; 
    border-radius: 50%;
    
}
.deal_colors_select{
    
    border-width:1px ;
}

.deal_colors_selected {
    border-width:3px ;
}
.deal_colors_selected  {
    border: 1px solid  !important;
}
